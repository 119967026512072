import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="parma1810">
<ImgDestra nome="lombardini.png" alt="Antonio Lombardini" didascalia="Antonio Lombardini"/>
<h1>La prima giovinezza a Parma</h1>
<p>Dopo la perdita della madre nel 1820 e la partenza del padre nel 1825, il quindicenne Antonio viene affidato insieme ai fratelli e alle sorelle allo zio materno, Antonio Lombardini (1794-1869), matematico e futuro Ministro delle Finanze del Ducato di Parma e Piacenza.</p>
<p>Antonio rivela un’intelligenza vivace e decide di iscriversi, forse per esaudire i desideri del padre, alla facoltà di medicina dell’Università di Parma. Tra i suoi professori vi è il fisico e patriota Macedonio Melloni, a cui dedicherà un’ode: in effetti, nonostante la branca di studi scelta, la vera passione di Antonio rimangono le lingue classiche, specialmente il greco, e la letteratura.</p>
<ImgCentro nome="parma.PNG" alt="parma" didascalia="Piazza Duomo, Parma"/>
<p>La tranquilla vita parmigiana e gli studi vengono però interrotti il 2 gennaio 1831 quando, in seguito alla sospensione di Macedonio Melloni e alla sostituzione con un professore più allineato al governo ducale, Antonio e altri studenti manifestano la loro solidarietà all’intellettuale e organizzano il boicottaggio delle lezioni del nuovo professore.<br />
Questo basta perché lui e sette dei suoi compagni siano prelevati dai gendarmi e rinchiusi nella fortezza di Compiano.</p>
<ImgCentro href="https://castelliemiliaromagna.it/it/s/compiano/6004-castello_di_compiano" nome="compiano.PNG" alt="compiano" didascalia="Castello di Compiano (PR)"/>
<p>Con lo scoppio dei moti rivoluzionari di Parma e in seguito alla fuga della duchessa Maria Luigia a Piacenza (14-15 febbraio), i prigionieri vengono però liberati. Antonio si arruola nella Guardia Nazionale del nuovo Governo Provvisorio della città e per un mese circa partecipa attivamente a diverse operazioni di guerra e di guerriglia. Per questa ragione, nel momento in cui viene ripristinato il governo austriaco, il 20 marzo 1831, Antonio è tra i pochi insorti a non essere perdonato.<br />
Costretto a nascondersi e poi a fuggire attraverso l’Appennino parmense, non tornerà più nella sua Parma per molto tempo.</p>
<h2>Link per approfondire</h2>
<p><A href="https://it.wikipedia.org/wiki/Antonio_Lombardini">“Antonio Lombardini”</A></p>
<p><A href="https://castelliemiliaromagna.it/it/s/compiano/6004-castello_di_compiano">“Castello di Compiano”</A></p>
<p><A href="https://www.casabracco.it/celsogallenga">“I moti rivoluzionari del 1830-31 ”</A></p>
<p><A href="https://www.treccani.it/enciclopedia/macedonio-melloni_%28Dizionario-Biografico%29/">“Macedonio Melloni”</A></p>
<p><A href="https://emiliaromagnaturismo.it/it/arte-cultura/citta-darte/parma-maria-luigia-asburgo">“Maria Luigia d’Austria”</A></p>

	</PaginaTesto>
}